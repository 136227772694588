<template>
  <div>
    <div>
      <span class="logo-box" />
      <p class="remark">{{ remark }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    remark: {
      type: String,
      default: 'CashAgad'
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.logo-box{
  display: block;
  margin: 0 auto;
  width: 160px;
  height: 160px;
  box-shadow: 0px 4px 20px 4px rgba(213,213,213,0.3000);
  border-radius: 24px;
  background: url('~@/assets/images/public/logo.png') no-repeat center center;
  background-size: 100%;
}
.remark{
  font-size: 48px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #002424;
  line-height: 56px;
  margin-top: 17px;
  text-align: center;
}
</style>
