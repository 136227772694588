<template>
  <baseTemplate :show-head="false">
    <div class="name-app">CashAgad</div>
    <div class="top-area">
      <logo ref="logo" class="logo" />
      <span class="user-id">ID:{{ dataForm.mobile }}</span>
    </div>
    <div class="bottom-area">
      <div class="email-list-box">
        <div @click="copyCode(dataForm.Umufikila_inkalata)" class="email-box">{{ $t('baseInfo.email') + '：'+ dataForm.Umufikila_inkalata }}<span class="copy-btn">{{ $t('public.copyBtn') }}</span></div>
        <div class="list-box">
          <ul>
            <li v-for="(item,index) in dataList" :key="index" class="list-item" @click="goLink(item.link)">
              <span :class="item.icon" class="icon" />
              <span class="custom-title">{{ $t(item.name) }}</span>
              <span v-if="item.isContent" class="cont">{{ item.isContent ?(dataForm.IfishilabelengwaKale ? dataForm.IfishilabelengwaKale +' '+ item.cont:'') : item.cont }}<span v-if="dataForm.IfishilabelengwaKale" class="msg-tips" /></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="bottom-box">
      <span class="index" @click="goIndex()" />
      <span class="user-center">{{ $t('userCenter.title') }}</span>
    </div> -->

    <van-popup v-model="popupshow" :close-on-click-overlay="false" >
      <div class="popup-box">
        <img class="popup-img" src="@/assets/images/userCenter/updata-img.png" alt="">
        <div class="popup-conten">
          <p class="popup-title">{{ $t('userCenter.gengxinbanben') }}</p>
          <p class="popup-p mt28">{{$t('userCenter.jiancebanmen',{v:version})  }}</p>
          <p class="popup-p">{{$t('userCenter.pleasecopelike')  }}</p>
          <p class="popup-link" @click="copyCode('https://bit.ly/LkAPh')"><span> https://bit.ly/LkAPh </span> {{$t('userCenter.copy')  }}</p>
          <p class="popup-p">{{$t('userCenter.pleaseupdataV')  }}</p>
          <div class="btn" @click="popupshow=false">{{$t('userCenter.iknow')  }}</div>
        </div>
      </div>
    </van-popup>

  </baseTemplate>
</template>

<script>
import Logo from '@/components/Logo'
import { getUserInfo } from '@/api/axios/index'
export default {
  components: { Logo },
  data() {
    return {
      popupshow:false,
      version:'',
      isLoan:false,
      dataForm: {
        email: '',
        mobile: '',
        mobile_as: '',
        IfishilabelengwaKale: 0,
        version: null,
        whatsapp: ''
      },
      dataList: [],
    }
  },
  created() {
    this.getUserInfo()
    this.$H5Object.changeStatusBarBgColor('#005FE9')
    this.$H5Object.changeStatusIsDarkFont(false)
    this.$H5Object.isShowBottomTabinter(true)
  },
  destroyed() {
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.isShowBottomTabinter(false)
    this.$H5Object.changeStatusIsDarkFont(true)
  },
  methods: {
    copyCode(contText) {
      this.$copyText(contText).then(() => {
        this.$toast.success(this.$t('public.copySuccess'))
      })
    },
    // 去首页
    goIndex() {
      this.$H5Object.homeclick()
    },
    goLink(linkPath) {
      console.log(linkPath);
      if (!linkPath) return
      if(linkPath=='/loanAgreementUse'){

        this.$router.push({
          path: linkPath,
          query:{
            type:'my',
          }
        })
      }else{
        this.$router.push({
          path: linkPath
        })
      }
      
    },

    // 获取用户信息
    async getUserInfo() {
      try {
        const { data } = await getUserInfo() || {}
        if(data.OZYGgAYz){
          this.dataList=[
            {
              icon: 'message-icon',
              name: 'userCenter.messagetext',
              link: '/messages',
              isContent: true,
              cont: this.$t('userCenter.messages')
            },
            {
              icon: 'account-icon',
              name: 'userCenter.PayRecord',
              link: '/paymentHistory'
            },
            {
              icon: 'account-loan',
              name: 'settings.loanAgreement',
              link: '/loanAgreementUse'
            },
            {
              icon: 'cupones-icon',
              name: 'cupones.wodekaquan',
              link: '/cupones'
            },
            {
              icon: 'settings-icon',
              name: 'settings.title',
              link: '/settings'
            }
          ]
        }else{
          this.dataList=[
            {
              icon: 'message-icon',
              name: 'userCenter.messagetext',
              link: '/messages',
              isContent: true,
              cont: this.$t('userCenter.messages')
            },
            {
              icon: 'account-icon',
              name: 'userCenter.PayRecord',
              link: '/paymentHistory'
            },
            {
              icon: 'cupones-icon',
              name: 'cupones.wodekaquan',
              link: '/cupones'
            },
            {
              icon: 'settings-icon',
              name: 'settings.title',
              link: '/settings'
            }
          ]
        }
        console.log(this.dataList,'this.dataList');
        this.dataForm = { ...this.dataForm, ...data }
        this.popupshow = data.Ukwingila
        this.version=data.version
        // this.$andirodData.Ubwafwilisho_Application = data.version
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-popup{
  background: none;
}

.popup-box{
  width: 670px;
  .popup-img{
    width: 100%;
    height:334px ;
  }
  .popup-conten{
    background: #FFF;
    border-radius:0 0 24px 24px;
    padding: 120px 44px 64px;
    margin-top: -120px;
    .popup-title{
      font-size: 48px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }
    .mt28{
      margin-top: 28px;
    }
    .popup-p{
      font-size: 32px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #292A4D;
      line-height: 48px;
      text-align: center;
    }
    .popup-link{
      width: 542px;
      background: #EBF3FF;
      border-radius: 16px;
      margin: 8px auto;
      padding: 8px 28px;
      font-size: 32px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      line-height: 48px;
      text-align: center;
      span{
        color: #005FE9;
        text-decoration: underline;
      }
    }
    .btn{
      width: 542px;
      height: 128px;
      background: #1C1D21;
      border-radius: 32px;
      line-height:128px ;
      text-align: center;
      margin: 0 auto;
      margin-top: 48px;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
:deep(.is-content){
  padding: 0;
}
.name-app{
  height: 88px;
  line-height: 88px;
  padding-left: 40px;
  font-size: 56px;
  font-weight: bold;
  color: #FFFFFF;
  background: #005FE9FF;
}
.is-content{
  .top-area{
    // width: 686px;
    // position: relative;
    width: 100%;
    min-height: 342px;
    padding-top: 20px;
    display: flex;
    background: url('~@/assets/images/userCenter/usercenter-top-bg-icon.png') no-repeat right 20px #005FE9FF;
    background-size: contain;
    .user-id{
      font-size: 40px;
      font-weight: bold;
      color: #FFFFFF;
      padding-top: 38px;
      padding-left: 20px;
      line-height: 84px;
    }
  }
  .list-box{
    background: #fff;
    margin-top: 60px;
    padding-top: 32px;
    position: relative;
    height: 580px;
    ul{
      background: #fff;
    }
    &::before{
      content: '';
      position: absolute;
      left: -40px;
      right: -40px;
      height: 60px;
      top: -58px;
      background: url('~@/assets/images/userCenter/hu-icon.png') no-repeat center center;
      background-size: 100%;
    }
    .list-item{
      position: relative;
      width: 100%;
      height: 72px;
      line-height: 72px;
      background: #FFFFFF;
      margin-bottom: 48px;

      .icon{
        width: 72px;
        height: 72px;
        display: table-cell;
      }
      .cont{
        font-size: 28px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #CCCCCC;
        position: absolute;
        top: 0;
        right: 80px;
        .msg-tips{
          position: relative;
          &::after{
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            top: 50%;
            margin-top: -8px;
            border-radius: 50%;
            background: #F7B014;
            margin-left: 12px;
          }
        }
      }
      .custom-title{
        display: table-cell;
        padding-left: 16px;
        font-weight: 400;
        font-size: 30px;
        color: #000000;
      }
      .message-icon{
        background: url(../../assets/images/userCenter/message-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .cupones-icon{
        background: url(../../assets/images/userCenter/cupones-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .account-icon{
        background: url(../../assets/images/userCenter/account-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .account-loan{
        background: url(../../assets/images/userCenter/account-loan.png) no-repeat center center;
        background-size: 100%;
      }

      .about-us-icon{
        background: url(../../assets/images/userCenter/about-us-icon.png) no-repeat center center;
        background-size: 100%;
      }

      .feed-back-icon{
        background: url(../../assets/images/userCenter/feed-back-icon.png) no-repeat center center;
        background-size: 100%;
      }
      .settings-icon{
        background: url(../../assets/images/userCenter/setting-icon.png) no-repeat center center;
        background-size: 100%;
      }
      // .mes-tel-icon{
      //   background: url(../../assets/images/userCenter/msg-tel-icon.png) no-repeat center center;
      //   background-size: 100%;
      // }
    }
    .list-item::after{
      content: '';
      width: 48px;
      height: 48px;
      position: absolute;
      background: url(../../assets/images/userCenter/center-next-icon.png) no-repeat center center;
      background-size: auto 100%;
      top: 50%;
      margin-top: -24px;
      right: 0;
    }
    .list-item.no-go::after{
      display: none;
    }
  }
}

:deep(.logo){
  .logo-box{
    width: 160px;
    height: 160px;
    box-shadow: none;
    margin: 0;
    margin-left: 40px;
    background: url('~@/assets/images/userCenter/uc-logo-icon.png') no-repeat center center;
    background-size: 100%;
  }
}
:deep(.logo .remark){
  display: none;
}

.bottom-area{
  padding: 0 40px;
  margin-top: -82px;
  z-index: 10;
  .email-list-box{
    height: 580px;
    padding-top: 38px;
    background: url('~@/assets/images/userCenter/black-bj-icon.png') no-repeat center center;
    background-size: 100%;
    position: relative;
    z-index: 2;
    &::after,&::before{
      content: '';
      position: absolute;
      width: 40px;
      left: -40px;
      height: 20px;
      top: 82px;
      z-index: -1;
      background: #005FE9FF;
    }
    &::after{
      left: initial;
      right: -40px;
    }
    .email-box{
      height: 44px;
      // text-align: center;
      font-size: 30px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #F7B014;
      line-height: 44px;
      position: relative;
      padding-left: 80px;
      &::before{
        content: '';
        position: absolute;
        width: 44px;
        height: 44px;
        left: 25px;
        background: url('~@/assets/images/userCenter/email-icon.png') no-repeat center center;
        background-size: 100%;
      }
    }
  }
}
.bottom-box{
  position: fixed;
  bottom: 40px;
  left: 40px;
  right: 40px;
  height: 128px;
  background: #1C1D21;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  border-radius: 64px;
  padding: 16px;
  display: grid;
  grid-template-columns: 50% 50%;
  span{
    position: relative;
  }
  .index{
    &::after{
      content: '';
      position: absolute;
      width: 56px;
      height: 56px;
      left: 50%;
      top: 50%;
      margin-left: -28px;
      margin-top: -28px;
      background: url('~@/assets/images/userCenter/index-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
  .user-center{
    height: 96px;
    border-radius: 48px;
    padding-left: 160px;
    line-height: 96px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    font-size: 28px;
    color: #1C1D21;
    text-shadow: 0px 8px 16px rgba(0,0,0,0.3);
    &::after{
      content: '';
      position: absolute;
      width: 56px;
      height: 56px;
      left: 58px;
      top: 20px;
      background: url('~@/assets/images/userCenter/my-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}
.copy-btn{
  background: #E7F0FC;
  border-radius: 20px;
  font-size: 30px;
  color: #1C1D21;
  margin-left: 8px;
  padding: 3px 15px;
}
</style>
